import React from 'react';
import {
  ScrollingProvider,
  useScrollSection,
  Section,
} from 'react-scroll-section';
import {Navbar, Nav , NavDropdown , Form , FormControl , Button,Dropdown  } from "react-bootstrap";

const light_backgroud_color='';
const light_background_text_color='';

const dark_backgroud_color='rgba(212,234,255,1)';
const dark_background_text_color='#fff';


const menu_item_style=(selected)=>{
	if (selected){
	return(
	{
	 display: 'inline-block',
  textAlign: 'center',
  //flex:1,
  //wordWrap: 'break-word',
  width:'10vw',
  cursor: 'pointer',
  transition: 'all 0.25s',
  margin: 0,
  padding: '25px 10px',
  fontWeight: 'bold',
  fontSize: '22px',
  userSelect: 'none',
  color: 'rgba(255,255,255,1)',
  borderBottom: '5px solid rgba(212,234,255,1)',
	})
	}
	else{
		return(
	{
	display: 'inline-block',
	 //flex:1,
	 //wordWrap: 'break-word',
	 width:'10vw',
  textAlign: 'center',
  cursor: 'pointer',
  transition: 'all 0.25s',
  margin: 0,
  padding: '25px 10px',
  fontWeight: 'bold',
  fontSize: '20px',
  userSelect: 'none',
  color: 'rgba(255,255,255,1)',
  //borderTop: '5px solid transparent',
 
	})
	}
}
const logo_style={
	display: 'inline-block',
	 cursor: 'pointer',
	height:'10vh',
	color:'transparent',
	backgroundColor:'transparent',
	borderWidth:0,
	outline: 'none',
	//width:'30vw',
	//height:'50vh',
	
 
	}
	var section_text_dark={
	fontWeight: 'regular',
  fontSize: '22px',
  
  color: 'rgba(255,255,255,1)',
	//height:'50vh',
	//textAlign: 'left',
 
	}
	const section_header_dark={
	fontWeight: 'bold',
	//textDecoration: 'underline',
  fontSize: '40px',
  
  
  color: 'rgba(255,255,255,1)',
	//height:'50vh',
	
 
	}
	var section_text_light={
	fontWeight: 'regular',
  fontSize: '22px',
  
  color: 'rgba(91,155,213,1)',
	//height:'50vh',
	//  textAlign: 'left',
  //textJustify: 'inter-word',
 
	}
	 if (window.innerHeight>window.innerWidth){

section_text_light={
	fontWeight: 'regular',
  fontSize: '22px',
  
  color: 'rgba(91,155,213,1)',
	//height:'50vh',
	  textAlign: 'left',
  //textJustify: 'inter-word',
 
	}
	 section_text_dark={
	fontWeight: 'regular',
	fontSize: '22px',
  
	color: 'rgba(255,255,255,1)',
	//height:'50vh',
	textAlign: 'left',
 
	}
	
	 }
	const section_header_light={
	fontWeight: 'bold',
	//textDecoration: 'underline',
  fontSize: '40px',
  
  
  color: 'rgba(91,155,213,1)',
	//height:'50vh',
	
 
	}
	const logo_section_style={
	 //display: 'inline-block',
	 // position: 'fixed',
	//left:0,
	//top:0,
	//flex:1,
	//cursor: 'pointer',
  transition: 'all 0.25s',
	//marginLeft: '15vh',
	//marginBottom:'auto',
	//margin:'auto',
	//alignSelf:'flex-start',
	//width:'10vw'
  //padding: '25px 10px',
  //borderTop: '5px solid transparent',
 
	}
	const links_section_style={
	 //display: 'inline-block',
	 // position: 'fixed',
	//left:0,
	//top:0,
	display:'flex',
	flex:1,
	
	flexDirection:'row',
	justifyContent:'space-evenly',
	
	width:'70vw'
 
 
	}
const StaticMenu_Old = () => {
  const homeSection = useScrollSection('home');
  const aboutSection = useScrollSection('about');

  return (
  
    <ul style={{
		backgroundColor:'rgba(91,155,213,1)', 
		 position: 'fixed',
  
		  width:'100vw',
  zIndex: 1,
  left: '50%',
  transform: 'translateX(-50%)',
  display: 'table',
  marginLeft: 'auto',
  marginRight: 'auto',
 // marginBottom:100,
 height:'15vh',
  margin: 0,
	}}>
      <li onClick={homeSection.onClick} style={logo_section_style} selected={homeSection.selected}>
        <img src='Logo.png' style={logo_style}/>
      </li>
	  <li onClick={homeSection.onClick} style={menu_item_style(homeSection.selected)} selected={homeSection.selected}>
        Home
      </li>
      <li onClick={aboutSection.onClick} style={menu_item_style(aboutSection.selected)}  selected={aboutSection.selected}>
        About
      </li>
    </ul>
	
	
  );
};
const StaticMenu = () => {
  const aboutusSection = useScrollSection('aboutus');
  const consultingSection = useScrollSection('consulting');
  const csrsection = useScrollSection('csr');
  const collaborationSection = useScrollSection('collaboration');
  const contactusSection = useScrollSection('contactus');
  if (window.innerHeight>window.innerWidth){
	   return (
  
   <div style={{
		display:'flex',
		flex:1,
		flexDirection:'row',
		justifyContent:'space-between',
		backgroundColor:'rgba(91,155,213,1)', 
		 position: 'fixed',
		alignItems:'center',
		  width:'100vw',
  zIndex: 1,
  //left: '50%',
  //transform: 'translateX(-50%)',
  //display: 'table',
  //marginLeft: '5vw',
	//marginRight: '500vw',
 //paddingLeft:'5vw',
 //paddingRight:'5vw',
 //padding:'5vw',
 // marginBottom:100,
 height:'15vh',
 // margin: 0,
	}}>
      <div onClick={aboutusSection.onClick} style={logo_section_style} >
        <img src='Logo.png' style={logo_style}/>
      </div>
	  <Dropdown>
  <Dropdown.Toggle id="dropdown-basic"  style={logo_style}>
   <img src='Menu_Icon.png' style={logo_style}/>
  </Dropdown.Toggle>

  <Dropdown.Menu  style={{
		display:'flex',
		flex:1,
		flexDirection:'column',
		justifyContent:'space-evenly',
		backgroundColor:'rgba(91,155,213,1)', 
		// position: 'fixed',
		alignItems:'center',
		
		  width:'100vw',
  zIndex: 1,
  //left: '50%',
  //transform: 'translateX(-50%)',
  //display: 'table',
  //marginLeft: 'auto',
 //marginRight: 'auto',
 //paddingLeft:'2vw',
 //paddingRight:'2vw',
 // marginBottom:100,
 //height:'15vh',
  //margin: 0,
	}}>
    <Dropdown.Item> <div onClick={aboutusSection.onClick} style={menu_item_style(aboutusSection.selected)} selected={aboutusSection.selected}>
			About Us
		  </div>  </Dropdown.Item>
    <Dropdown.Item > <div onClick={consultingSection.onClick} style={menu_item_style(consultingSection.selected)} selected={consultingSection.selected}>
			Consulting
		  </div></Dropdown.Item>
		     <Dropdown.Item > <div onClick={collaborationSection.onClick} style={menu_item_style(collaborationSection.selected)} selected={collaborationSection.selected}>
			Collaborations
		  </div></Dropdown.Item>
    <Dropdown.Item > <div onClick={csrsection.onClick} style={menu_item_style(csrsection.selected)} selected={csrsection.selected}>
			CSR
		  </div></Dropdown.Item>
		  <Dropdown.Item > <div onClick={contactusSection.onClick} style={menu_item_style(contactusSection.selected)}  selected={contactusSection.selected}>
			Contact Us
		  </div></Dropdown.Item>
  </Dropdown.Menu>
</Dropdown>
	 
    </div>
	
  );

  }
  else{
  return (
  
    <div style={{
		display:'flex',
		flex:1,
		flexDirection:'row',
		justifyContent:'space-between',
		backgroundColor:'rgba(91,155,213,1)', 
		 position: 'fixed',
		alignItems:'center',
		  width:'100vw',
  zIndex: 1,
  //left: '50%',
  //transform: 'translateX(-50%)',
  //display: 'table',
  //marginLeft: 'auto',
 //marginRight: 'auto',
 paddingLeft:'2vw',
 paddingRight:'2vw',
 // marginBottom:100,
 height:'15vh',
 // margin: 0,
	}}>
      <div onClick={aboutusSection.onClick} style={logo_section_style} >
        <img src='Logo.png' style={logo_style}/>
      </div>
	  <div  style={links_section_style} selected={aboutusSection.selected}>
		  <div onClick={aboutusSection.onClick} style={menu_item_style(aboutusSection.selected)} selected={aboutusSection.selected}>
			About Us
		  </div>  
		     <Dropdown.Item > <div onClick={consultingSection.onClick} style={menu_item_style(consultingSection.selected)} selected={consultingSection.selected}>
			Consulting
		  </div></Dropdown.Item>
		  <div onClick={collaborationSection.onClick} style={menu_item_style(collaborationSection.selected)} selected={collaborationSection.selected}>
			Collaborations
		  </div>
		  <div onClick={csrsection.onClick} style={menu_item_style(csrsection.selected)} selected={csrsection.selected}>
			CSR
		  </div>
		  <div onClick={contactusSection.onClick} style={menu_item_style(contactusSection.selected)}  selected={contactusSection.selected}>
			Contact Us
		  </div>
      </div>
    </div>
	
	
  );
  }
};
const section_style={
	
			 minHeight: '100vh',
			 //width: '100%',
			  display: 'flex',
			  //margin: 'auto',
			  //flex: '0 1 auto',
			  flex: 1,
			  flexDirection: 'column',
			  justifyContent: 'center',
			  textAlign: 'center',
			  scrollBehavior: 'smooth',
			  //position: 'relative',		
	
	
}
const section_style_dark={
				backgroundColor:'rgba(91,155,213,1)', 
			 minHeight: '100vh',
			// width: '100%',
			  display: 'flex',
			  //margin: 'auto',
			  //flex: '0 1 auto',
			  flex: 1,
			  flexDirection: 'column',
			  justifyContent: 'center',
			  textAlign: 'center',
			  scrollBehavior: 'smooth',
			  //position: 'relative',	
			   alignItems:'center',
			//padding:50,
	
	
}
const section_style_light={
				backgroundColor:'rgba(255,255,255,1)', 
			 minHeight: '100vh',
			  width: '100vw',
			  display: 'flex',
			 // margin: 'auto',
			  //flex: '0 1 auto',
			  flex: 1,
			  flexDirection: 'column',
			  justifyContent: 'center',
			  textAlign: 'center',
			  scrollBehavior: 'smooth',
			  //position: 'relative',	
			  alignItems:'center',
			//padding:50,
	
	
}

export const HomeScreen = () => (
  <ScrollingProvider>
  
    <StaticMenu />
	
	
	<div style={{backgroundColor:'rgba(213,234,255,1)',height:'100%',width:'100vw'}}>
	
    <Section id="aboutus" style={section_style_dark}>  
		
			<div  style={section_style_dark}>
			<div  style={{padding:10}}>
	
				<p style={section_text_dark}>AddVentures helps startups in Pre-seed through Series A accelerate their growth, come up with a solid pitch, access to and raise funds, network with industry leaders. We can train you how to pitch your idea to investors and make introductions.</p>
			</div>
			</div>
			<div  style={section_style_light}>
			<div  style={{padding:10}}>
				<p style={section_header_light}>Who should get in touch with us</p>
				<img style={{width:'20vh',height:'20vh'}} src='graph_increasing_black.png'/>
				<p style={section_text_light}>We strongly encourage startups who have an MVP and offer environmentally friendly solutions (i.e. water conservation, decarbonization, renewables) to get in touch with us.</p>
			 </div>
			 </div>
		 
	</Section>
    <Section id="consulting" style={section_style_dark}>
		<div  style={section_style_dark}>
			<div  style={{padding:10}}>
			<p style={section_header_dark}>Consulting</p>
<p style={section_text_dark}>AddVentures has deep expertise in the cement industry and building materials. We occasionally participate in global cement conferences to provide insights on the latest developments and trends in the cement sector.</p>
			</div>
		</div>
	</Section>
	<Section id="collaboration" style={section_style_light}>
		<div  style={section_style_light}>
			<div  style={{padding:10}}>
			
				<p style={section_header_light}>Collaborations</p>
					{
						//<img style={{width:'20vh',height:'20vh'}} src='collaboration.png'/>
					}
				<p style={section_text_light}>We collaborate with the global leader in events devoted to the cement market. </p>
	
				<p style={section_text_light}>AddVentures proudly participates in Intercem to discuss topics related to decarbonization and cement supply and demand. </p>
					{
						<iframe title="vimeo-player" src="https://player.vimeo.com/video/528004832" width={window.innerWidth*0.50} height={window.innerWidth*0.28} frameborder="0" allowfullscreen></iframe>
					}
			</div>
		</div>
	</Section>
	<Section id="csr" style={section_style_dark}>
		<div  style={section_style_dark}>
			<div  style={{padding:10}}>
			<p style={section_header_dark}>Corporate Social Responsibility</p>
			
			<p style={section_text_dark}>As part of giving back to community, we participate in the CFA Research Challenge to mentor young and ambitious talents to develop their investment skillset </p>
		</div>
		</div>
	</Section>
	<Section id="contactus" style={section_style_light}>
	<div  style={section_style_light}>
			<div  style={{padding:10}}>
			
				<p style={section_header_light}>Contact Us Now</p>
				<br />
				<br />
				<br />
				<div style={{display:'flex', flex:1 , flexDirection:'row',alignItems:'center',justifyContent:'space-around'}}>
				<img style={{width:30,backgroundColor:'rgba(91,155,213,1)',border: '1px solid rgba(91,155,213,1)'}} src='Mail_Icon.png'/>
				<a href="mailto:info@add-ventures.com" style={{fontSize:25}}>info@add-ven.com</a>
			</div>
			</div>
		</div>
	</Section>
	</div>
	
  </ScrollingProvider>
);