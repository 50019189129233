import logo from './logo.svg';
import {HomeScreen} from './Pages/HomeScreen';
//import NavBar  from './NavBar/NavBar';
import './App.css';

function App() {
  return (
    <div className="App">
      
	   <div style={{backgroundColor:'rgba(0,0,0,0.7)',height:'100%'}}>
	
		<HomeScreen /> 
		 </div>
    </div>
  );
}

export default App;
